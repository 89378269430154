import { Switch as AntSwitch } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import theme from '../../../assets/theme';

export type SwitchProps = {
  disabled?: boolean;
  color: string;
  type?: 'default' | 'big';
  uncheckedText?: string;
  checkedText?: string;
  style?: React.CSSProperties;
  value?: boolean;
  onClick?: (checked: boolean, event: MouseEvent) => void;
  onChange?: (checked: boolean, event: MouseEvent) => void;
};

export const Switch: React.FC<SwitchProps> = props => {
  const {
    style,
    type,
    color,
    disabled,
    uncheckedText,
    checkedText,
    value,
    onClick,
    onChange,
    ...rest
  } = props;

  const withTextValue = uncheckedText !== undefined && checkedText !== undefined;
  const typeValue = type === undefined ? 'default' : type;

  return (
    <StyledSwitch
      $styleType={typeValue}
      $color={color}
      $withText={withTextValue}
      disabled={disabled}
      unCheckedChildren={uncheckedText}
      checkedChildren={checkedText}
      checked={value}
      onClick={onClick}
      onChange={onChange}
      style={style}
      {...rest}
    />
  );
};

interface StyledSwitchProps {
  $styleType: 'default' | 'big';
  $color: string;
  $withText: boolean;
}

const StyledSwitch = styled(AntSwitch)<StyledSwitchProps>`
  background-color: #d9d9d9;
  min-width: 40px;

  &:focus {
    box-shadow: none;
  }

  /** Disabling Animation on Click */
  .ant-click-animating-node {
    animation: none !important;
  }

  &.ant-switch-checked {
    background-color: ${props => props.$color};
  }

  .ant-switch-handle:before {
    box-shadow: none;
  }

  ${props =>
    props.$withText &&
    css`
      padding: 10px;
      height: 40px;
      min-width: 110px;

      .ant-switch-inner {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 900;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition-duration: 0.5s;
      }

      &:not(.ant-switch-checked) > .ant-switch-inner {
        color: ${theme.colors.blueDark};
        margin: 0 0 0 20px;
      }

      &.ant-switch-checked > .ant-switch-inner {
        color: #ffffff;
        margin: 0 20px 0 0;
      }

      & > .ant-switch-handle {
        top: 10px;
        width: 20px;
        height: 20px;
        transition-duration: 0.5s;

        :before {
          border-radius: 10px;
        }
      }

      &.ant-switch-checked > .ant-switch-handle {
        left: calc(100% - 20px - 10px);
      }

      &:not(.ant-switch-checked) > .ant-switch-handle {
        left: 10px;
      }
    `}

  ${props =>
    props.$styleType === 'big' &&
    css`
      /** Disabling Animation on Click */
      [ant-click-animating]:after {
        animation: none !important;
      }

      border-radius: 100px;

      height: 150px;
      width: 330px;

      &,
      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
        box-shadow: inset 10px 10px 20px rgba(25, 56, 89, 0.2);
      }

      .ant-switch-handle {
        top: 20px;
        transition-duration: 0.5s;

        height: 110px;
        width: 110px;

        :before {
          content: none;
        }

        :after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          background: #edf0f8;
          box-shadow: inset -1px -1px 2px rgba(5, 57, 87, 0.23), inset 1px 1px 2px #87a3cd,
            inset -2px -2px 4px #ffffff;
          border: 10px solid #ffffff;
          border-radius: 100px;
        }
      }

      &.ant-switch-checked > .ant-switch-handle {
        left: calc(100% - 110px - 20px);
      }

      &:not(.ant-switch-checked) > .ant-switch-handle {
        left: 20px;
      }
    `}

    ${props =>
      props.$withText &&
      props.$styleType === 'big' &&
      css`
        .ant-switch-inner {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
        }

        &:not(.ant-switch-checked) > .ant-switch-inner {
          margin: 0 0 0 110px;
          text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.5),
            -3px -3px 5px rgba(255, 255, 255, 0.3),
            0.5px 0.5px 0.5px rgba(142, 142, 142, 0.5),
            1px 1px 1px rgba(129, 129, 129, 0.3), 3px 3px 4px rgba(155, 155, 155, 0.4);
        }

        &.ant-switch-checked > .ant-switch-inner {
          margin: 0 110px 0 0;
          text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.5),
            -3px -3px 5px rgba(255, 255, 255, 0.3),
            0.5px 0.5px 0.5px rgba(164, 117, 74, 0.5), 1px 1px 1px rgba(126, 86, 48, 0.3),
            3px 3px 4px rgba(150, 127, 67, 0.4);
        }
      `}
`;
