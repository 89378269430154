import React from 'react';

export const LaptopSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9115 15.0747V15.4151H8.10768V15.0747H0V15.2147C0 15.4351 0.0799967 15.6551 0.240381 15.8155C0.400374 15.9755 0.620756 16.0558 0.841137 16.0558H19.1588C19.3792 16.0558 19.5992 15.9758 19.7595 15.8155C19.9195 15.6555 19.9999 15.4351 19.9999 15.2147V15.0747H11.9115ZM2.6827 15.6351H1.84192V15.5351H2.6827V15.6351Z"
      fill="currentColor"
    />
    <path
      d="M2.38217 14.7542H17.6167C17.7567 14.7542 17.8971 14.6942 17.9971 14.5942C18.0971 14.4942 18.1571 14.3538 18.1571 14.2138V4.48422C18.1571 4.34422 18.0971 4.20384 17.9971 4.10384C17.8971 4.00385 17.7567 3.94385 17.6167 3.94385H2.38217C2.24217 3.94385 2.10179 4.00385 2.00179 4.10384C1.90179 4.20384 1.8418 4.34422 1.8418 4.48422V14.2134C1.8418 14.3534 1.90179 14.4938 2.00179 14.5938C2.12179 14.7142 2.24217 14.7542 2.38217 14.7542ZM10.0095 4.18423C10.1095 4.18423 10.1895 4.26423 10.1895 4.36422C10.1895 4.46422 10.1095 4.54421 10.0095 4.54421C9.90947 4.54421 9.82948 4.46422 9.82948 4.36422C9.82948 4.26423 9.90947 4.18423 10.0095 4.18423ZM2.70258 4.78459H17.3168V13.9134H2.70258V4.78459Z"
      fill="currentColor"
    />
  </svg>
);

export default LaptopSvg;
