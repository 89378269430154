import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Spin } from 'antd';
import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Hotkeys from 'react-hot-keys';
import QRCode from 'react-qr-code';
import styled, { css } from 'styled-components';

import { CheckSvg, CopySvg } from '../../../assets/icons';
import ArrowRightSvg from '../../../assets/icons/ArrowRightSvg';
import LaptopSvg from '../../../assets/icons/LaptopSvg';
import MobileSvg from '../../../assets/icons/MobileSvg';
import QuickPlaySvg from '../../../assets/icons/QuickPlaySvg';
import theme from '../../../assets/theme';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import { Switch } from '../_Switch';
import { Button } from '../Button';
import { IconWrapper } from '../IconWrapper';

const { Panel } = Collapse;

const ModerationOptions = ({
  pin,
  qrLink,
  onCustomClick,
}: {
  pin: string;
  qrLink: string;
  onCustomClick?: () => void;
}) => {
  const handleOpen = () => {
    onCustomClick && onCustomClick();
  };

  const isDesktop = useBreakPoint(1000);

  const [pinCopied, setPinCopied] = useState(false);
  useEffect(() => {
    const ref = setTimeout(() => {
      setPinCopied(false);
    }, 3000);
    return () => {
      clearTimeout(ref);
    };
  }, [pinCopied]);

  return (
    <div style={{ display: 'grid', gap: 10 }}>
      <Button
        type="ghost"
        onClick={() => handleOpen()}
        styles={{ color: 'white', borderColor: 'white', width: '100%', height: '42px' }}
      >
        Open
      </Button>
      <CollapseStyled
        ghost
        expandIconPosition={'right'}
        expandIcon={({ isActive }) => (
          <StyledArrowIconWrapper
            height="24px"
            width="24px"
            $isActive={isActive}
            color="white"
          >
            <ArrowRightSvg />
          </StyledArrowIconWrapper>
        )}
      >
        <Panel key="1" header="Cannot moderate on this device?">
          <div style={{ width: '100%' }}>
            <div>
              <div style={{ display: 'flex', width: '100%', gap: 5, color: 'white' }}>
                <IconWrapper height="20px" width="20px">
                  <LaptopSvg />
                </IconWrapper>
                <span style={{ flex: '1' }}>Another PC</span>
                <CopyToClipboard
                  text={`${window.location.origin}/moderate?pin=${pin}`}
                  onCopy={() => {
                    setPinCopied(true);
                  }}
                >
                  <IconWrapper height="20px" width="20px">
                    {!pinCopied && <CopySvg />}
                    {pinCopied && <CheckSvg />}
                  </IconWrapper>
                </CopyToClipboard>
              </div>
              <div style={{ width: '100%', color: 'white' }}>
                <p style={{ fontSize: 12, marginBottom: 2 }}>
                  - Go to{' '}
                  <CopyToClipboard
                    text={`${window.location.origin}/moderate?pin=${pin}`}
                    onCopy={() => {
                      setPinCopied(true);
                    }}
                  >
                    <span>
                      <span
                        style={{
                          color: theme.colors.white,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {window.location.origin}/moderate
                      </span>
                      {pinCopied && (
                        <span style={{ textDecoration: 'unset' }}> copied!</span>
                      )}
                    </span>
                  </CopyToClipboard>
                </p>
                <p style={{ fontSize: 12, marginBottom: 2 }}>- Enter the PIN {pin} </p>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                background: 'white',
                height: 1,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: isDesktop ? 'auto 130px' : 'auto 80px',
              }}
            >
              <div>
                <div style={{ display: 'flex', width: '100%', gap: 5, color: 'white' }}>
                  <IconWrapper height="20px" width="20px">
                    <MobileSvg />
                  </IconWrapper>
                  <span style={{ flex: '1' }}>iPad / Mobile device</span>
                </div>
                <div style={{ width: '100%', color: 'white' }}>
                  <p style={{ fontSize: 12, marginBottom: 2 }}>
                    - Scan QR code with camera
                  </p>
                </div>
              </div>
              {isDesktop ? (
                <div
                  style={{
                    padding: '5px',
                    backgroundColor: 'white',
                    width: '130px',
                    height: '130px',
                    borderRadius: '5px',
                  }}
                >
                  <QRCode value={qrLink} size={120} />
                </div>
              ) : (
                <div
                  style={{
                    padding: '5px',
                    backgroundColor: 'white',
                    width: '80px',
                    height: '80px',
                    borderRadius: '5px',
                  }}
                >
                  <QRCode value={qrLink} size={70} />
                </div>
              )}
            </div>
          </div>
        </Panel>
      </CollapseStyled>
    </div>
  );
};

const CollapseStyled = styled(Collapse)`
  background-color: transparent;
  border: none;

  .ant-collapse-arrow {
    height: 24px;
    display: flex;
    align-items: center;
    right: 0px !important;
  }

  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-extra {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .ant-collapse-header {
    padding-right: 70px !important;
    color: white !important;
    text-decoration: underline !important;
    padding: 0px !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const StyledArrowIconWrapper = styled(IconWrapper)<{ $isActive?: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      && svg {
        transform: rotate(90deg);
      }
    `}
`;

export const DropdownButtonItem = ({
  icon,
  title,
  description,
  includeSwitch,
  onSwitchToggled,
  switchValue,
  qrLink,
  isDarkTheme,
  showOptions,
  pin,
  onCustomClick,
}: DropdownButtonItemProps) => {
  const [pinCopied, setPinCopied] = useState(false);
  useEffect(() => {
    const ref = setTimeout(() => {
      setPinCopied(false);
    }, 3000);
    return () => {
      clearTimeout(ref);
    };
  }, [pinCopied]);
  return (
    <MenuItem
      $isDarkTheme={!!isDarkTheme}
      onClick={() => onSwitchToggled && onSwitchToggled(!switchValue)}
    >
      <Left $isDarkTheme={!!isDarkTheme}>{icon}</Left>
      <Right $isDarkTheme={!!isDarkTheme}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              margin: 0,
              fontWeight: 'bold',
              fontSize: 18,
              color: isDarkTheme ? 'white' : theme.colors.translucentLightBlack,
            }}
          >
            {title}
          </p>
          {includeSwitch && (
            <Switch
              style={{ marginLeft: 10 }}
              color={theme.colors.primary}
              value={switchValue}
              onChange={(bool, e) => {
                e.stopPropagation();
                onSwitchToggled && onSwitchToggled(bool);
              }}
            />
          )}
        </div>
        <p
          style={{
            marginTop: isDarkTheme ? 2 : 4,
            marginBottom: 2,
            fontSize: isDarkTheme ? 10 : 14,
            lineHeight: isDarkTheme ? 1.3 : 1.5,
            fontWeight: 'bold',
            color: isDarkTheme ? 'white' : theme.colors.translucentLightBlack,
          }}
        >
          {description}
        </p>
        {showOptions && qrLink && pin && (
          <ModerationOptions pin={pin} qrLink={qrLink} onCustomClick={onCustomClick} />
        )}
      </Right>
    </MenuItem>
  );
};

export type DropdownButtonItemProps = {
  icon: React.ReactNode;
  title: string;
  description?: string;
  includeSwitch?: boolean;
  switchValue?: boolean;
  onSwitchToggled?: (bool: boolean) => void;
  qrLink?: string;
  isDarkTheme: boolean;
  showOptions?: boolean;
  pin?: string;
  onCustomClick?: () => void;
};

const DropDownButton = ({
  onClick,
  children,
  menu,
  isDisabled = false,
  isLoading,
  style,
  isPresentButton,
  icon = <QuickPlaySvg />,
  isCreateLessonButton,
}: {
  menu: React.ReactElement;
  onClick?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isPresentButton?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  isCreateLessonButton?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEscapePress = () => {
    setShowDropdown(false);
  };

  return (
    <StyledDropdownButton $isDisabled={isDisabled} style={style}>
      <LeftContainer
        onClick={() => onClick && !isDisabled && onClick()}
        $isPresentButton={isPresentButton}
        $isCreateLessonButton={isCreateLessonButton}
      >
        {isLoading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />}
          />
        ) : (
          <>{icon}</>
        )}
        {children && <span>{children}</span>}
      </LeftContainer>
      <>
        <Dropdown
          placement="bottomRight"
          visible={showDropdown && !isDisabled}
          onVisibleChange={setShowDropdown}
          trigger={['click']}
          overlay={menu}
        >
          <DropdownButton
            $isPresentButton={isPresentButton}
            $isCreateLessonButton={isCreateLessonButton}
          >
            <PaddedBorder
              style={{ marginRight: '10px' }}
              $isPresentButton={isPresentButton}
            />
            <ArrowRightSvg />
          </DropdownButton>
        </Dropdown>
      </>
      <Hotkeys keyName="Escape" onKeyUp={handleEscapePress} />
    </StyledDropdownButton>
  );
};

const Right = styled.div<{ $isDarkTheme: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Left = styled.div<{ $isDarkTheme: boolean }>`
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  svg {
    height: 22px;
    width: 22px;
    ${({ $isDarkTheme }) =>
      $isDarkTheme &&
      css`
        color: ${theme.colors.white};
      `}
  }
`;

const MenuItem = styled.div<{ $isDarkTheme: boolean }>`
  display: flex;

  max-width: 375px;
  ${({ $isDarkTheme }) =>
    $isDarkTheme
      ? css`
          padding: 2px 0px;
        `
      : css`
          padding: 10px 0px;
        `}
  p {
    white-space: normal;
  }

  @media (max-width: 1000px) {
    max-width: 275px;
  }
`;

const PaddedBorder = styled.div<{ $isPresentButton?: boolean }>`
  width: 1px;
  background-color: ${({ $isPresentButton }) =>
    $isPresentButton ? theme.colors.black : 'rgba(255, 255, 255, 0.5);'};
  height: 36px;
  border-radius: 50px;
`;

const ButtonStyles = styled.div<{
  $isDisabled?: boolean;
  $isPresentButton?: boolean;
  $isCreateLessonButton?: boolean;
}>`
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);



  ${({ $isPresentButton, $isDisabled }) =>
    $isPresentButton &&
    !$isDisabled &&
    css`
      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.white};
      }
    `}
    
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover,
      &:focus,
      &:active {
        background-color: gray;
      }
    `}


  ${({ $isCreateLessonButton, $isDisabled }) =>
    $isCreateLessonButton &&
    !$isDisabled &&
    css`
      background-color: ${theme.colors.orange};

      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.orangeDark};
      }
    `}
`;

const DropdownButton = styled(ButtonStyles)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 10px;
  && svg {
    transform: rotate(90deg);
    height: 22px;
    width: 22px;
  }
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const LeftContainer = styled(ButtonStyles)`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  flex: 1;
  height: 100%;
  & > *:not(:first-child) {
    margin-left: 10px;
  }

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledDropdownButton = styled.button<{
  $isDisabled?: boolean;
}>`
  background-color: ${({ $isDisabled }) => ($isDisabled ? 'gray' : theme.colors.primary)};
  color: ${theme.colors.white};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.7 : 1)};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  font-size: 16px;
  display: flex;
  font-family: 'Nunito';
  font-style: normal;
  line-height: 22px;
  width: fit-content;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  outline: none;
  border: none;
  padding: 0;
  border-radius: 5px;
`;

export default DropDownButton;
