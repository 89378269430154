import React from 'react';

export const MobileSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8335 1.6665H9.8335C9.55717 1.6665 9.3335 1.89018 9.3335 2.1665C9.3335 2.44283 9.55717 2.6665 9.8335 2.6665H11.8335C12.1098 2.6665 12.3335 2.44283 12.3335 2.1665C12.3335 1.89018 12.1098 1.6665 11.8335 1.6665Z"
      fill="currentColor"
    />
    <path
      d="M14 0H6C4.89734 0 4 0.897344 4 2V18C4 19.1027 4.89734 20 6 20H14C15.1027 20 16 19.1027 16 18V2C16 0.897344 15.1027 0 14 0ZM15 18C15 18.5523 14.5523 19 14 19H6C5.44766 19 5 18.5523 5 18V2C5 1.44766 5.44766 1 6 1H14C14.5523 1 15 1.44766 15 2V18Z"
      fill="currentColor"
    />
    <path
      d="M10 18.3335C10.5523 18.3335 11 17.8858 11 17.3335C11 16.7812 10.5523 16.3335 10 16.3335C9.44772 16.3335 9 16.7812 9 17.3335C9 17.8858 9.44772 18.3335 10 18.3335Z"
      fill="currentColor"
    />
    <path
      d="M8.1665 2.6665C8.44265 2.6665 8.6665 2.44265 8.6665 2.1665C8.6665 1.89036 8.44265 1.6665 8.1665 1.6665C7.89036 1.6665 7.6665 1.89036 7.6665 2.1665C7.6665 2.44265 7.89036 2.6665 8.1665 2.6665Z"
      fill="currentColor"
    />
  </svg>
);

export default MobileSvg;
